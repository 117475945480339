export const profileContent = {
  EN: {
    title: "Giga Razki Arianda",

    profileImage: "/assets/myprofile.jpg",
    location: "Bangkalan, Indonesia",
    bio: `As a Software Engineer with under a year of experience in Web Development and Artificial Intelligence, I am passionate about coding
and problem-solving. This drive has led me to explore various programming languages and frameworks, always seeking opportunities to
grow my skills and expertise. I thrive on tackling new challenges and enjoy collaborating with others to develop innovative solutions that
positively impact the tech industry.`,
    education: "Education",
    experience: "Experience",
    certificate: "Certificate",
    techStack: "Tech Stack",
    socialMedia: {
      linkedin: "https://linkedin.com/in/gigarazkiarianda",
      github: "https://github.com/gigarazkiarianda",
      twitter: "https://twitter.com/gigarazkiarianda",
    },
    schools: [
      {
        name: "Universitas Dinamika",
        location: "Surabaya, Indonesia",
        degree: "Computer Engineering",
        years: "2020 - 2024",
      },
      {
        name: "SMA Negeri 2 Bangkalan",
        location: "Bangkalan, Indonesia",
        degree: "Science",
        years: "2017 - 2020",
      },
    ],
    jobs: [
      {
        company: "PT Integradata Cipta Tech",
        title: "Software Engineer",
        years: "Oct 2024 - Now",
        location: "Surabaya, Indonesia",
        type: "on-site",
        jobtype: "Contract",
      },
      {
        company: "PT Salam Pacific Indonesia Lines",
        title: "Artificial Intelligence Engineer",
        years: "May 2024 - July 2024",
        location: "Surabaya, Indonesia",
        type: "on-site",
        jobtype: "internship",
      },
    ],
    certificates: [
      {
        name: "Machine Learning for All",
        Link: "https://www.coursera.org/account/accomplishments/verify/6MJ6FQX68RNP",
        year: "July 2024",
        issuer: "University Of London",
      },
      {
        name: "Supervised Learning: Regression and Classification",
        Link: "https://www.coursera.org/account/accomplishments/verify/F8KCPBRHSN55",
        year: "July 2024",
        issuer: "DeepLearning.AI, Coursera, Stanford CPD, UVM",
      },
      {
        name: "Unsupervised Learning: Recommenders, Reinforcement Learning",
        Link: "https://www.coursera.org/account/accomplishments/verify/LNYTWAFR4GXE",
        year: "July 2024",
        issuer: "DeepLearning.AI, Coursera, Stanford CPD, UVM",
      },
      {
        name: "Version Control",
        Link: "https://www.coursera.org/account/accomplishments/verify/SBKAHQV3MXS4",
        year: "July 2024",
        issuer: "Meta",
      },
      {
        name: "Version Control with Git",
        Link: "https://www.coursera.org/account/accomplishments/verify/RV9AP5FD9FSW",
        year: "July 2024",
        issuer: "Atlassian",
      },
    ],
    techStackDetails: {
      programmingLanguages: [
        {
          name: "HTML5",
          badge: "/assets/icon/html.svg",
          level: "Intermediate",
        },
        { name: "CSS3", badge: "/assets/icon/css.svg", level: "Intermediate" },
        {
          name: "JavaScript",
          badge: "/assets/icon/javascript.svg",
          level: "Intermediate",
        },
        {
          name: "Python",
          badge: "/assets/icon/python.svg",
          level: "Intermediate",
        },
        { name: "C++", badge: "/assets/icon/cplus.svg", level: "Beginner" },
        { name: "PHP", badge: "/assets/icon/PHP.svg", level: "intermediate" },
        {
          name: "Typescript",
          badge: "/assets/icon/typescript.svg",
          level: "beginner",
        },
      ],
      databases: [
        { name: "MySQL", badge: "/assets/icon/mysql.svg", level: "beginner" },
        {
          name: "Postgres",
          badge: "/assets/icon/postgresql.svg",
          level: "Beginner",
        },
      ],
      frontendFrameworks: [
        {
          name: "TailwindCSS",
          badge: "/assets/icon/tailwindcss.svg",
          level: "beginner",
        },
        {
          name: "Bootstrap",
          badge: "/assets/icon/bootstrap.svg",
          level: "Intermediate",
        },
        {
          name: "React",
          badge: "/assets/icon/react.svg",
          level: "Intermediate",
        },
        { name: "Expo", badge: "/assets/icon/expo.svg", level: "Beginner" },
        {
          name: "STREAMLIT",
          badge: "/assets/icon/streamlit.svg",
          level: "Beginner",
        },
      ],
      backendFramework: [
        {
          name: "Express.js",
          badge: "/assets/icon/expressjs.svg",
          level: "Beginner",
        },
        { name: "Flask", badge: "/assets/icon/flask.svg", level: "Beginner" },
      ],
      FullStackFramework: [
        {
          name: "Laravel",
          badge: "/assets/icon/Laravel.svg",
          level: "Beginner",
        },
        {
          name: "Codeigniter",
          badge: "/assets/icon/codeigniter.svg",
          level: "beginner",
        },
      ],
      hosting: [
        { name: "Vercel", badge: "/assets/icon/vercel.svg", level: "Beginner" },
      ],
      AI: [
        {
          name: "Keras",
          badge: "/assets/icon/keras.svg",
          level: "Intermediate",
        },
        {
          name: "Matplotlib",
          badge: "/assets/icon/matplotlib.svg",
          level: "Intermediate",
        },
        {
          name: "NumPy",
          badge: "/assets/icon/numpy.svg",
          level: "Intermediate",
        },
        {
          name: "Pandas",
          badge: "/assets/icon/pandas.svg",
          level: "Intermediate",
        },
        {
          name: "scikit-learn",
          badge: "/assets/icon/scikit-learn.svg",
          level: "Intermediate",
        },
        {
          name: "TensorFlow",
          badge: "/assets/icon/tensorflow.svg",
          level: "Intermediate",
        },
      ],
      projectControl: [
        { name: "Git", badge: "/assets/icon/git.svg", level: "Intermediate" },
        {
          name: "Postman",
          badge: "/assets/icon/postman.svg",
          level: "Intermediate",
        },
      ],
      IDEs: [
        {
          name: "Jupyter Notebook",
          badge: "/assets/icon/jupyter.svg",
          level: "Intermediate",
        },
        {
          name: "Visual Studio Code",
          badge: "/assets/icon/visual-studio-code.svg",
          level: "Intermediate",
        },
        {
          name: "Arduino",
          badge: "/assets/icon/arduino.svg",
          level: "Beginner",
        },
      ],
      design: [
        {
          name: "Figma",
          badge: "/assets/icon/figma.svg",
          level: "Intermediate",
        },
        {
          name: "Adobe XD",
          badge: "/assets/icon/xd.svg",
          level: "Intermediate",
        },
        {
          name: "Adobe Photoshop",
          badge: "/assets/icon/photoshop.svg",
          level: "Intermediate",
        },
        {
          name: "Adobe Illustrator",
          badge: "/assets/icon/illustrator.svg",
          level: "Intermediate",
        },
      ],
      projectManager: [
        {
          name: "Trello",
          badge: "/assets/icon/trello.svg",
          level: "Intermediate",
        },
        {
          name: "Notion",
          badge: "/assets/icon/notion.svg",
          level: "Intermediate",
        },
      ],
    },
  },

  ID: {
    title: "Giga Razki Arianda",
    location: "Bangkalan, Indonesia",
    bio: `Sebagai seorang Insinyur Perangkat Lunak dengan pengalaman kurang dari satu tahun dalam Pengembangan Web dan Kecerdasan Buatan, saya sangat menyukai pengodean dan pemecahan masalah. Dorongan ini telah mendorong saya untuk menjelajahi berbagai bahasa pemrograman dan kerangka kerja, selalu mencari peluang untuk mengembangkan keterampilan dan keahlian saya. Saya bersemangat dalam menghadapi tantangan baru dan senang berkolaborasi dengan orang lain untuk mengembangkan solusi inovatif yang berdampak positif pada industri teknologi.`,
    education: "Pendidikan",
    experience: "Pengalaman",
    certificate: "Sertifikat",
    techStack: "Teknologi",
    schools: [
      {
        name: "Universitas Dinamika",
        location: "Surabaya, Indonesia",
        degree: "Teknik Komputer",
        years: "2020 - 2024",
      },
      {
        name: "SMA Negeri 2 Bangkalan",
        location: "Bangkalan, Indonesia",
        degree: "IPA",
        years: "2017 - 2020",
      },
    ],
    jobs: [
      {
        company: "PT Integradata Cipta Tech",
        title: "Software Engineer",
        years: "Oct 2024 - Now",
        location: "Surabaya, Indonesia",
        type: "on-site",
        jobtype: "Contract",
      },
      {
        company: "PT Salam Pacific Indonesia Lines",
        title: "AI Engineer",
        years: "Mei 2024 - Juli 2024",
        location: "Surabaya, Indonesia",
        type: "on-site",
        jobtype: "magang",
      },
    ],
    certificates: [
      {
        name: "Machine Learning for All",
        Link: "https://www.coursera.org/account/accomplishments/verify/6MJ6FQX68RNP",
        year: "Juli 2024",
        issuer: "University Of London",
      },
      {
        name: "Supervised Learning: Regression and Classification",
        Link: "https://www.coursera.org/account/accomplishments/verify/F8KCPBRHSN55",
        year: "Juli 2024",
        issuer: "DeepLearning.AI, Coursera, Stanford CPD, UVM",
      },
      {
        name: "Unsupervised Learning: Recommenders, Reinforcement Learning",
        Link: "https://www.coursera.org/account/accomplishments/verify/LNYTWAFR4GXE",
        year: "Juli 2024",
        issuer: "DeepLearning.AI, Coursera, Stanford CPD, UVM",
      },
      {
        name: "Version Control",
        Link: "https://www.coursera.org/account/accomplishments/verify/SBKAHQV3MXS4",
        year: "Juli 2024",
        issuer: "Meta",
      },
      {
        name: "Version Control with Git",
        Link: "https://www.coursera.org/account/accomplishments/verify/RV9AP5FD9FSW",
        year: "Juli 2024",
        issuer: "Atlassian",
      },
    ],
    techStackDetails: {
      programmingLanguages: [
        {
          name: "HTML5",
          badge: "/assets/icon/html.svg",
          level: "Intermediate",
        },
        { name: "CSS3", badge: "/assets/icon/css.svg", level: "Intermediate" },
        {
          name: "JavaScript",
          badge: "/assets/icon/javascript.svg",
          level: "Intermediate",
        },
        {
          name: "Python",
          badge: "/assets/icon/python.svg",
          level: "Intermediate",
        },
        { name: "C++", badge: "/assets/icon/cplus.svg", level: "Beginner" },
        { name: "PHP", badge: "/assets/icon/PHP.svg", level: "intermediate" },
        {
          name: "Typescript",
          badge: "/assets/icon/typescript.svg",
          level: "beginner",
        },
      ],
      databases: [
        { name: "MySQL", badge: "/assets/icon/mysql.svg", level: "beginner" },
        {
          name: "Postgres",
          badge: "/assets/icon/postgresql.svg",
          level: "Beginner",
        },
      ],
      frontendFrameworks: [
        {
          name: "TailwindCSS",
          badge: "/assets/icon/tailwindcss.svg",
          level: "beginner",
        },
        {
          name: "Bootstrap",
          badge: "/assets/icon/bootstrap.svg",
          level: "Intermediate",
        },
        {
          name: "React",
          badge: "/assets/icon/react.svg",
          level: "Intermediate",
        },
        { name: "Expo", badge: "/assets/icon/expo.svg", level: "Beginner" },
        {
          name: "STREAMLIT",
          badge: "/assets/icon/streamlit.svg",
          level: "Beginner",
        },
      ],
      backendFramework: [
        {
          name: "Express.js",
          badge: "/assets/icon/expressjs.svg",
          level: "Beginner",
        },
        { name: "Flask", badge: "/assets/icon/flask.svg", level: "Beginner" },
      ],
      FullStackFramework: [
        {
          name: "Laravel",
          badge: "/assets/icon/Laravel.svg",
          level: "Beginner",
        },
        {
          name: "Codeigniter",
          badge: "/assets/icon/codeigniter.svg",
          level: "beginner",
        },
      ],
      hosting: [
        { name: "Vercel", badge: "/assets/icon/vercel.svg", level: "Beginner" },
      ],
      AI: [
        {
          name: "Keras",
          badge: "/assets/icon/keras.svg",
          level: "Intermediate",
        },
        {
          name: "Matplotlib",
          badge: "/assets/icon/matplotlib.svg",
          level: "Intermediate",
        },
        {
          name: "NumPy",
          badge: "/assets/icon/numpy.svg",
          level: "Intermediate",
        },
        {
          name: "Pandas",
          badge: "/assets/icon/pandas.svg",
          level: "Intermediate",
        },
        {
          name: "scikit-learn",
          badge: "/assets/icon/scikit-learn.svg",
          level: "Intermediate",
        },
        {
          name: "TensorFlow",
          badge: "/assets/icon/tensorflow.svg",
          level: "Intermediate",
        },
      ],
      projectControl: [
        { name: "Git", badge: "/assets/icon/git.svg", level: "Intermediate" },
        {
          name: "Postman",
          badge: "/assets/icon/postman.svg",
          level: "Intermediate",
        },
      ],
      IDEs: [
        {
          name: "Jupyter Notebook",
          badge: "/assets/icon/jupyter.svg",
          level: "Intermediate",
        },
        {
          name: "Visual Studio Code",
          badge: "/assets/icon/visual-studio-code.svg",
          level: "Intermediate",
        },
        {
          name: "Arduino",
          badge: "/assets/icon/arduino.svg",
          level: "Beginner",
        },
      ],
      design: [
        {
          name: "Figma",
          badge: "/assets/icon/figma.svg",
          level: "Intermediate",
        },
        {
          name: "Adobe XD",
          badge: "/assets/icon/xd.svg",
          level: "Intermediate",
        },
        {
          name: "Adobe Photoshop",
          badge: "/assets/icon/photoshop.svg",
          level: "Intermediate",
        },
        {
          name: "Adobe Illustrator",
          badge: "/assets/icon/illustrator.svg",
          level: "Intermediate",
        },
      ],
      projectManager: [
        {
          name: "Trello",
          badge: "/assets/icon/trello.svg",
          level: "Intermediate",
        },
        {
          name: "Notion",
          badge: "/assets/icon/notion.svg",
          level: "Intermediate",
        },
      ],
    },
  },
};
